import React from 'react'
import MetaImage from '../../assets/images/story/learnship/cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import LearnshipBody from '../../components/story/learnship/Content'
import LearnshipHeader from '../../components/story/learnship/Header'

const LearnshipPage = () => {
  return (
    <Layout
      title="Learnship case study"
      description="Learnship increases visibility and speeds up engineering workflows with Swarmia."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <LearnshipHeader />
        <QuoteBlock person="gero" />
        <LearnshipBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best software teams get better. Get started for free.
      </CTASection>
    </Layout>
  )
}
export default LearnshipPage
