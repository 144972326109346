import React from 'react'
import image1 from '../../../assets/images/story/learnship/learnship-1.png'
import image2 from '../../../assets/images/story/learnship/learnship-2.png'
import image3 from '../../../assets/images/story/learnship/learnship-3.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'
const LearnshipBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Learnship helps global teams communicate effectively by offering
            online, face-to-face language and cultural training.
          </p>
        ),
        href: 'https://learnship.com',
        location: <p>Headquartered in Germany, global operations</p>,
        headcount: <p>300+ employees, 50+ in product and engineering</p>,
        customers: <p>2024</p>,
      }}
      main={
        <>
          <p>
            Gero Keller is the CTPO of Learnship, leading an organization of
            engineers, designers, and product managers located across the globe.
            When Learnship‘s product and engineering organization expanded to
            nine teams in 2024, maintaining a clear understanding of initiatives
            and aligning efforts became increasingly difficult. Teams operated
            with varying practices, and visibility into the development process
            was limited.
          </p>
          <InlineQuote
            quote="When we scaled up to nine teams, getting a cohesive leadership view of progress was challenging. The lack of a single source of truth also made it tough to confirm that we were working on the most important things."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            Learnship‘s Jira lacked traceability; tickets often appeared as
            “done” even when the work was incomplete. Attempts to analyze
            engineering data manually from GitHub and Jira proved ineffective,
            as the data was outdated by the time it was reviewed.
          </p>
          <InlineQuote
            quote="We relied on Jira metrics, like velocity and burndown charts, and GitHub data, such as commits and pull requests, supplemented by retrospective discussions. While this worked when we were smaller, it became more fragmented as we grew."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            It became clear to Gero that they needed real-time engineering
            metrics — views that could be relied upon for both high-level
            strategic planning and team-level optimization.
          </p>
          <h2>
            DIY dashboards or dedicated software: How Learnship made the
            decision
          </h2>
          <p>
            Gero wanted a solution that would help the organization reduce cycle
            times, minimize the number of stagnating tickets, and create
            consistency in their release cadence.
          </p>
          <p>
            First, Learnship entertained the idea of building their own
            solution, but they soon realized that a dedicated software
            engineering intelligence platform would fit their needs better.
          </p>
          <InlineQuote
            quote="We looked at various engineering analytics tools and briefly considered building a homegrown solution using our data warehouse. However, we needed something that would give immediate insights without a large internal development overhead."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            After abandoning the idea of building a DIY solution, Learnship
            settled on four key criteria:
          </p>
          <ul>
            <li>Visibility into the flow from Jira to production</li>
            <li>
              Cycle time and work-in-progress tracking to reduce context
              switching
            </li>
            <li>
              Initiative and epic alignment to tie engineering work to business
              goals
            </li>
            <li>
              Actionable dashboards that teams would use daily, and that
              wouldn‘t just exist for executive reporting
            </li>
          </ul>
          <InlineQuote
            quote="We wanted real-time, holistic visibility into our engineering workflows. We needed a better way to see exactly where tickets got stuck and whether we were overloaded with too much work in progress at any given time."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            Gero and the team looked at Swarmia, LinearB, and Sleuth. In the
            end, the decision came down to real-time metrics, customizability,
            and leadership features.
          </p>
          <InlineQuote
            quote="Swarmia‘s seamless integrations with GitHub and Jira, plus its built-in best practices, appealed to us. It offered real-time data, an intuitive UI, and customizable workflows to align with our specific definition of 'done'. We also appreciated the initiative-tracking feature, letting us see how each epic was advancing toward strategic objectives."
            name="Gero Keller"
            title="CTPO"
          />
          <h3>How Learnship introduced engineering metrics to their team</h3>
          <p>
            Learnship first rolled out Swarmia to a few domain teams. They did
            data cleanup and configurations in Jira to ensure a smooth pilot for
            the rest of the teams.
          </p>
          <InlineQuote
            quote="We ran a pilot with a couple of domain teams that were already experimenting with new approaches. The pilot helped us refine our definitions of 'done' and how we configured our Jira boards."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            Once the pilot teams noticed tangible improvements, like faster
            identification of stale tickets and better visibility into
            initiatives, Learnship gradually started introducing Swarmia to
            other teams. The incremental approach helped them get valuable
            feedback, address concerns, and fine-tune systems.
          </p>
          <p>
            Some developers were concerned that Swarmia would be used to
            micromanage or rate individual performance with vanity metrics. To
            address this, Learnship involved team leads in setting up dashboards
            and metrics to foster a sense of ownership and collaboration. Gero
            also ensured that all teams knew that Swarmia wasn‘t used for
            individual tracking, but understanding team- and organization-level
            workflows.
          </p>
          <InlineQuote
            quote="We let the people doing the work influence how the tool is set up and how metrics are interpreted, and use the data to refine processes, not to point fingers."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            Gero believes that the best ways to reduce friction when introducing
            engineering metrics are by communicating their purpose clearly,
            providing support through training and onboarding, and highlighting
            the immediate benefits a platform like Swarmia brings.
          </p>
          <h3>First learnings</h3>
          <p>
            Immediately after the rollout, Learnship noticed several key
            productivity insights: The organization‘s average cycle time was
            surprisingly high due to an overload of work-in-progress tickets.
            Teams were juggling multiple tasks concurrently, leading to
            excessive context switching and long delivery times.
          </p>
          <p>
            They also learned that code review times varied significantly
            between teams, although the primary factor contributing to this
            inconsistency was the excessive amount of WIP items. The tickets
            that were in limbo were either marked done without actually reaching
            production or were awaiting quality assurance sign-off.
          </p>
          <h3>Long-term changes</h3>
          <p>
            After fully implementing Swarmia, Learnship saw a 20-25% reduction
            in cycle times, 25% less time spent on maintenance work, and
            improved engineering satisfaction through increased transparency and
            ownership. The number of open pull requests in progress dropped from
            80 to 15.
          </p>
          <ProductScreenshot src={image1} />
          <h3>Faster production</h3>
          <p>
            Gero and the team were able to establish stricter WIP limits,
            streamline the QA handover process, and create a process for
            addressing stale PRs, resulting in a 20-25% reduction in average
            cycle times, more predictable code review turnaround, and consistent
            deployment frequency.
          </p>
          <InlineQuote
            quote="Swarmia definitely reinforced a culture of continuous improvement and data-driven decision-making. Teams began framing discussions around actual metrics — like cycle times and PR review durations — rather than gut feelings. This helped reduce friction and improve accountability."
            name="Gero Keller"
            title="CTPO"
          />
          <h3>Increased visibility and alignment</h3>
          <p>
            With the whole engineering organization having access to engineering
            metrics, facilitating retros and sprint planning sessions became
            easier, leading to better-informed decision-making.
          </p>
          <InlineQuote
            quote="Balancing the quantitative data with qualitative retrospectives is key to fostering a healthy, high-performing engineering culture."
            name="Gero Keller"
            title="CTPO"
          />
          <h3>Improved team well-being</h3>
          <p>
            The teams now experience increased ownership and responsibility as
            they‘ve gained a uniform and transparent way to measure
            productivity. The ability to see their progress and the impact of
            their work has boosted morale and confidence.
          </p>
          <InlineQuote
            quote="After a short adjustment period, Swarmia led to greater ownership and responsibility among engineers and QA. They could clearly see what needed attention and when."
            name="Gero Keller"
            title="CTPO"
          />
          <h3>Stronger connection to business outcomes</h3>
          <p>
            Learnship also highlighted the value of Swarmia in justifying
            engineering investments, such as refactoring and reducing technical
            debt by tying them to measurable outcomes like stability. After
            implementing Swarmia, they‘ve reduced the time spent on KTLO
            (keeping the lights on) work by over 25%. Now, more time is freed up
            for building new features, improving the existing ones, and
            investing in general productivity improvements.
          </p>
          <ProductScreenshot src={image2} />
          <InlineQuote
            quote="By mapping epics and initiatives to company-level goals, we can quickly show stakeholders how each sprint or set of tasks ties back to strategic priorities. This alignment reduces confusion and helps non-technical folks see the direct impact of engineering work."
            name="Gero Keller"
            title="CTPO"
          />
          <h3>More accurate financial planning</h3>
          <p>
            Learnship uses Swarmia to plan and track the cost of engineering
            initiatives. This helps the organization plan, prioritize, and
            compare different projects more reliably, and communicate with the
            finance and leadership teams.
          </p>
          <ProductScreenshot src={image3} />
          <InlineQuote
            quote="By correlating engineering outputs with strategic initiatives, we can present data-driven arguments to finance and executive teams. This makes budgeting and prioritization more transparent and grounded in measurable outcomes."
            name="Gero Keller"
            title="CTPO"
          />
          <p>
            Gero strongly believes that organizations, big and small, can
            benefit from tracking engineering insights, if they‘re introduced in
            the right way. He also encourages his team to keep an open dialogue
            to adapt metrics as the organization evolves.
          </p>
          <InlineQuote
            quote="Every organization is unique, and metrics alone aren‘t a panacea; they’re a lens to guide smarter decisions. However, I firmly believe that in a few years, not using a tool like Swarmia will make an engineering organization look dated and behind the curve."
            name="Gero Keller"
            title="CTPO"
          />
        </>
      }
    />
  )
}
export default LearnshipBody
