import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const LearnshipHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Learnship"
      heading="How Learnship increases visibility and speeds up engineering workflows with Swarmia"
      subheading="Learnship saw a 20-25% reduction in cycle time, a 25% drop in time spent on maintenance work, and improved engineering satisfaction after implementing Swarmia."
      featured={featured}
    />
  )
}

export default LearnshipHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/learnship/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
